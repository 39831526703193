@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
  min-width: rem(700px);
  min-height: rem(200px);
  height: 80%;
}

.modalInner {
  display: flex;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  min-width: rem(700px);
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  align-items: flex-start;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: auto;
  padding-top: 0;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.publishButton {
  padding: rem(16px) rem(50px);
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;
}

.publishButtonText {
  color: white;
}

.fileInputButton {
  background-color: transparent;
  width: rem(200px);
  height: rem(200px);
  border-radius: rem(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: rem(1.5px) solid black;

  &:hover {
    background-color: darken(white, 5%);
  }

  &::before {
    content: '+';
    font-size: rem(36px);
    color: black;
  }

  input[type='file'] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
}

.previewImage {
  width: rem(200px);
  height: rem(200px);
  border-radius: rem(10px);
  object-fit: contain;
}
