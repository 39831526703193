@import '../../styles/abstract/variables';
@import '../../styles/abstract/mixins';

form {
  @include container;
  @include media-max($eycrowd-screen-tablet-landscape) {
    width: 100%;
  }
}

.uploadButonRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fileNameDisplay {
  margin-left: 10px;
}

.campaignsContainer {
  margin-top: 20px;
  flex: 1;
}

.socialMediaInput {
  padding: rem(5px);
  border-radius: rem(8px);
  margin-bottom: rem(10px);
  width: rem(300px);
}

.campaignDescription {
  font-size: rem(18px);
  color: $eycrowd-color-text;
  margin-top: $eycrowd-gutter-sm;
  margin-bottom: $eycrowd-gutter-sm;
}

.summaryContainer {
  padding: 2rem;
  border-radius: 15px;
  margin-top: $eycrowd-gutter-xl;
  margin-bottom: $eycrowd-gutter-xl;
  border: 1px solid $eycrowd-color-blue-01;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  max-width: rem(1180px);
}

.summaryTitle {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-text;
  margin-bottom: $eycrowd-gutter-sm;
}

.summaryText {
  font-size: $eycrowd-font-size-body;
  color: $eycrowd-color-gray;
  line-height: $eycrowd-font-line-height-stack;
}

.socialMediaTitle {
  font-size: $eycrowd-font-size-md;
  color: $eycrowd-color-text;
  margin-top: $eycrowd-gutter-xl;
  margin-bottom: $eycrowd-gutter-sm;
}

.socialMediaLinks {
  a {
    font-size: $eycrowd-font-size-body;
    color: $eycrowd-color-blue;
    text-decoration: none;
    margin-right: $eycrowd-gutter-sm;
    @include text-hover;
    @include media-hover {
      text-decoration: underline;
    }
  }
}

.nextStepButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $eycrowd-radius-md;
  background-color: $eycrowd-color-blue;
  box-shadow: $eycrowd-shadow-blue-2;

  opacity: 1;
  color: $eycrowd-color-white;
  border: none;
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
  border: 1px solid transparent;
}

.spinner {
  z-index: 5;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  right: 0;
}

.formGroup {
  display: inline-flex;
  flex-direction: column;
  //   margin-bottom: 20px;
  position: relative;

  label {
    font-size: $eycrowd-font-size-sm;
    line-height: $eycrowd-font-line-height-xxs;
    color: $eycrowd-color-light-gray-01;
    margin-bottom: rem(4px);
    font-weight: 500;
    display: flex;
  }

  input[type='file'] {
    &::-webkit-file-upload-button {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #f7f7f7;
      color: #333;
      cursor: pointer;
    }
  }

  .inputError {
    border-color: #ff6b6b;
  }

  .inputFeedback {
    color: #ff6b6b;
    font-size: 12px;
    margin-top: 5px;
  }
}

.jsonInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 15px;
  width: 150px;
}
.uploadButton {
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  color: #333;
  cursor: pointer;
}
// Responsive design
@include media-max($eycrowd-screen-tablet-landscape) {
  .summaryTitle,
  .socialMediaTitle {
    font-size: $eycrowd-font-size-md;
  }

  .socialMediaLinks a {
    font-size: $eycrowd-font-size-sm;
  }
}
