@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
  min-width: rem(700px);
  min-height: rem(200px);
  height: 90%;
}

.modalInner {
  display: flex;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: $eycrowd-font-size-xl;
  color: $eycrowd-color-text;
}

.sectionTitle {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-gray-01;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: auto;
  padding-top: 0;
}

.closeButton {
  padding: rem(16px) rem(50px);
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;

  width: rem(200px);
  margin: rem(10px) auto;
}

.closeButtonText {
  color: white;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.childContainer {
  display: flex;
  align-items: center;
  gap: rem(5px);
  flex-direction: column;
}

.childContainer_third {
  width: 33%;
}

.childContainer_half {
  width: 50%;
}

.childContainer_quarter {
  width: 25%;
}

.screenshotImage {
  width: 100%;
  height: auto;
  border-radius: rem(15px);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $eycrowd-color-gray-01;
}
