@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  padding: rem(16px);
}

.title {
  margin-bottom: rem(12px);
  color: $eycrowd-color-black-02;
}

.wrapper {
  border-bottom: $eycrowd-border-gray-01;
  margin-bottom: rem(16px);
}

.infoRow {
  display: flex;
  align-items: center;
  margin-bottom: rem(16px);
}

.label {
  min-width: 50%;
  opacity: 0.5;
  color: $eycrowd-color-black-03;
  font-size: $eycrowd-font-size-sm;
}

.infoText {
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
  font-size: $eycrowd-font-size-sm;
}

.saveButton {
  padding: 0 rem(20px);
  width: rem(180px);
  height: rem(40px);
  border-radius: $eycrowd-radius-sm !important;
  margin: 0 0 rem(30px) auto;
}

.field {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: $eycrowd-font-size-stack;
}

.input {
  height: rem(44px);
  width: 100%;
  padding: rem(10px) 0 rem(10px) rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  outline: none;
  font-family: $eycrowd-font-stack;
  color: $eycrowd-color-black-02;
  font-size: $eycrowd-font-size-stack;

  &::placeholder {
    color: $eycrowd-color-light-gray-01;
  }

  &:focus {
    border: 1px solid $eycrowd-color-red;
  }
}

.selectable {
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
}

.privateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(16px);

  p {
    font-size: rem(16px);
    font-weight: 500;
    min-width: rem(200px);
  }
}
