@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
  min-width: rem(700px);
  min-height: rem(500px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}

.publishButton {
  padding: rem(16px) rem(50px);
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;
  position: absolute;
  bottom: rem(20px);
  right: rem(20px);
}

.uploadButton {
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;
  width: rem(120px);
  height: rem(80px);
}

.publishButtonText {
  color: white;
}
