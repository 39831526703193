@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  height: 100%;
}

.title {
  color: $eycrowd-color-black-02;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(16px) rem(16px) 0;
}

.addAction {
  text-decoration: underline;
  cursor: pointer;
}

.list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: rem(733px);
  height: 100%;
  padding-bottom: 80px;
}

.listItem {
  display: flex;
  align-items: center;
  border-bottom: $eycrowd-border-gray-01;
  padding: rem(16px);
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }
}

.image {
  height: rem(34px);
  width: rem(34px);
  border-radius: $eycrowd-radius-round;
  margin-right: rem(8px);
}

.name {
  color: $eycrowd-color-black-02;
  font-weight: 500;
  font-size: $eycrowd-font-size-sm;
}

.amount {
  color: $eycrowd-color-red;
  font-weight: 600;
  font-size: $eycrowd-font-size-sm;
  margin-left: auto;
}

.actionButton {
  background-color: transparent;
  border: none;
  color: $eycrowd-color-red-02;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
}

.dropdownWrapper {
  position: absolute;
  top: 10px;
  left: -120px;
  border-radius: $eycrowd-radius-sm;
  width: rem(155px);
  background: $eycrowd-color-white;
  box-shadow: $eycrowd-box-shadow;
  overflow: hidden;
}

.dropdownButton {
  background: transparent;
  width: 100%;
  padding: rem(10px) 0;
  font-size: rem(14px);
  border: none;
  transition: background-color 0.4s, color 0.4s;
  font-weight: 500;

  &:hover {
    background-color: $eycrowd-color-red;
    color: $eycrowd-color-white;
  }
}
