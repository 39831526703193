@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.card {
  border-radius: $eycrowd-radius-md;
  background-color: $eycrowd-color-white;
  box-shadow: $eycrowd-shadow;
  padding: rem(10px) rem(20px);
  margin: rem(18px) 0;
  position: relative;

  .active {
    border: $eycrowd-border-active;
  }

  @include media-max($eycrowd-screen-mobile-portrait) {
    padding: rem(10px);
  }
}
