@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: rem(16px);
  max-width: rem(1300px);
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1.55fr 1fr 1fr;
  grid-gap: rem(16px);
  margin-bottom: rem(16px);
}

.firstRowBox {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.firstRowBoxLeft {
  width: 100%;
}

.secondRowBoxLeft {
  padding: rem(16px) rem(25px);
  width: 100%;
}

.rightBoxes {
  display: grid;
  grid-column: span 2;
  grid-gap: rem(16px);
  height: 100%;
}

.userBoxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: rem(16px);
}

.invisible {
  display: none;
}

.galleryWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-width: rem(1300px);
}

.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: rem(1px);
  grid-row-gap: rem(1px);
  margin-bottom: rem(1px);
}

.imagePlaceHolder {
  display: flex;
  flex: 1;
  min-width: rem(320px);
  min-height: rem(320px);
  margin-right: rem(5px);
  margin-bottom: rem(5px);
  cursor: pointer;
  position: relative;
}

.image {
  width: rem(320px);
  height: rem(320px);
  object-fit: cover;
  z-index: 2;
}

.galleryTitle {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-all;
}

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
  min-width: rem(700px);
  min-height: rem(500px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.title {
  font-weight: normal;
  font-size: 18px;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}

.formWrap {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputWrapperSm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  width: 100%;
  height: rem(40px);
}

.submitButton {
  height: rem(50px);
  width: rem(200px);
}

.campaignDetailsContentBox {
  margin-bottom: rem(16px);
}
