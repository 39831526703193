@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.preCampaignTasks.preCampaignTasks,
.liveCampaignTasks.liveCampaignTasks,
.followUpTasks.followUpTasks {
  margin: 0;
}

.preCampaignTasks {
  border-bottom: 24px solid $eycrowd-color-primary;
}

.liveCampaignTasks {
  border-bottom: 24px solid $eycrowd-color-red;
}

.followUpTasks {
  border-bottom: 24px solid $eycrowd-color-blue-01;
}

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr rem(80px);
  grid-gap: rem(24px);
  align-items: end;
}

.button {
  height: rem(60px);
  margin-bottom: rem(16px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  background-color: $eycrowd-color-white;
  color: $eycrowd-color-light-gray-01;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: rem(20px);
    height: rem(20px);
    border: 2px solid $eycrowd-color-light-gray-01;
    border-radius: 50%;
    font-weight: 600;
  }
}

.disabled {
  background-color: rgba($eycrowd-color-light-gray, 0.1);
  opacity: 0.5;
  cursor: not-allowed;
}

.title {
  color: $eycrowd-color-black-02;
}

.step {
  opacity: 0.5;
  color: $eycrowd-color-black-02;
  margin-bottom: rem(9px);
  font-size: $eycrowd-font-size-sm;
}

.taskDesc {
  font-weight: 500;
  color: $eycrowd-color-blue-dark;
  margin-bottom: rem(12px);
  word-break: break-all;
}
