/**
 * Base styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  color: $eycrowd-color-text;
  font-family: $eycrowd-font-stack;
  font-size: $eycrowd-font-size-body;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  transition: $eycrowd-transition;
}

html,
body,
#app {
  display: flex;
  flex-direction: column;
}

body,
#app {
  flex: 1 1 auto;
}

html,
body,
main,
#root {
  display: flex;
  flex-direction: column;
}
body,
main,
#root {
  flex: 1 1 auto;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $eycrowd-color-app-bg;
  min-height: 100%;
}

body {
  line-height: $eycrowd-font-line-height-sm;
  background-color: transparent;

  @media not all and (min-resolution: 0.001dpcm) {
    #root {
      //border: $eycrowd-size-safari-layout-fix solid transparent;
      //margin: -#{$eycrowd-size-safari-layout-fix};
    }
  }
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

a {
  color: $eycrowd-color-text;
  cursor: pointer;
}

svg {
  fill: currentColor;
}

th,
td {
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//used in src/containers/Notifier/index.js
.Qsa3au2gGYhs57eE {
  background: transparent;
  border: none;

  svg {
    width: 10px;
    height: 10px;
  }
}
