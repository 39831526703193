@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.campaignsTableWrapper {
  border: 1px solid #b8b8b8;
  background: white;
  max-height: calc(100vh - 258px);
  overflow: auto;
  box-shadow: $eycrowd-side-shadow;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &:hover::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 1px solid #bfbfbf;
    border-right: 0;
    border-bottom: 0;
    //border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    //border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c6c6c6;
    cursor: pointer;
  }
}

.bulkTableWraper {
  max-height: calc(100vh - 320px);
}

.simpleButton {
  text-decoration: underline;
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    top: 0;
    position: sticky;
    background-color: white;

    tr {
      th {
        border-bottom: 0.5px solid $eycrowd-color-light-gray-01;
      }
    }
  }

  .tbody {
    .tr {
      &:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .th,
  .td {
    text-align: left;
    color: $eycrowd-color-black-02;
    font-weight: 500;
    font-size: $eycrowd-font-size-sm;
    line-height: $eycrowd-font-line-height-sm;
    white-space: nowrap;
    border-bottom: 0.5px solid $eycrowd-color-light-gray-01;

    &:last-of-type {
      text-align: right;
    }

    &:nth-of-type(3) {
      min-width: rem(150px);
    }
  }

  .th {
    opacity: 0.5;
    padding: rem(22px) rem(20px) rem(10px);

    &:last-of-type {
      text-align: right;
    }

    &.sortable {
      cursor: pointer;
    }

    &.sorted {
      color: $eycrowd-color-green;
    }
  }

  .td {
    padding: rem(17px) rem(20px);
    word-break: break-all;
    min-width: 260px;

    &:first-of-type {
      padding: rem(17px) rem(5px) rem(17px) rem(20px);
    }

    &:last-of-type {
      min-width: 0;
    }

    &:nth-of-type(2) {
      word-break: break-all;
    }

    &:nth-of-type(6) {
      text-transform: capitalize;
    }
  }
}

.editIcon {
  width: rem(16px);
  cursor: pointer;
}

.actionButton {
  background-color: transparent;
  border: none;
}

.typeField {
  text-transform: capitalize;
}

.noDataTd {
  padding: rem(17px) rem(20px);
  white-space: nowrap;
  font-weight: 600;
  text-align: center;
}

.tdContentFlexCenter {
  display: flex;
  align-items: center;
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 0.5rem;
  left: 0.5rem;
  position: relative;
  vertical-align: top;
  width: 0.5rem;
  top: 0.15rem;
}

.chevron.bottom:before {
  transform: rotate(135deg);
}

.chevron.top:before {
  top: 0.3rem;
  transform: rotate(-45deg);
}

:root {
  --select-border: #e8eaed;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.paginationContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1rem;

  .select {
    position: relative;
    width: 80px;
    height: 40px;
    padding-left: 5px;
    border: 1px solid var(--select-border);
    box-shadow: $eycrowd-shadow;
    border-radius: 0.25em;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: #fff;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;

    &:after {
      margin-right: 0.2em;
      content: '';
      width: 0.6em;
      height: 0.4em;
      justify-self: end;
      background-color: var(--select-arrow);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      outline: none;
      appearance: none;
      padding: 0em 0.2em;
      background-color: transparent;
      border: none;
      margin: 0;
      width: 100%;
      line-height: inherit;
      cursor: pointer;
      z-index: 1;

      option {
        color: #223254;

        &:focus {
          outline: none;
          border-color: #0077ff;
          box-shadow: 0 0 0 2px rgba(#0077ff, 0.2);
        }

        &:hover {
          stroke: #0077ff;
        }
      }
    }
  }

  select,
  .select:after {
    grid-area: select;
  }
}

.bulkButton {
  padding: 0 rem(20px);
  min-width: rem(150px);
  height: rem(40px);
  margin-bottom: rem(10px);
  border-radius: $eycrowd-radius-sm !important;
  background-color: $eycrowd-color-blue-02;
  color: white;
}

.bulkButton:hover {
  background-color: $eycrowd-color-blue;
  color: white;
}

.bulkButtonDisabled {
  background-color: $eycrowd-color-light-gray-01;
  color: white;
}

.bulkButtonDisabled:hover {
  background-color: $eycrowd-color-light-gray-01;
  color: white;
}

.checkbox {
  padding: rem(17px) rem(20px);
  word-break: break-all;
}

.campaignName {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
}
.userFullName {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
}

.underlinedUsername {
  display: inline-block;
  padding-bottom: 0.02px;
  border-bottom: 1px solid gray;
}

.log {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
}

.notApprovedIcon {
  padding-left: 4px;
  height: 24px;
  width: 24px;
}

.eyCrowdIcon {
  padding-left: 4px;
  height: 20px;
  width: 20px;
}

.actionsWrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
}

.actionButtons {
  display: flex;
  gap: rem(20px);
}

.deleteIconWrapper {
  cursor: pointer;
}
