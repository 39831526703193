@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.galleryWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-width: rem(1300px);
}

.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: rem(1px);
  grid-row-gap: rem(1px);
  margin-bottom: rem(1px);
}

.imagePlaceHolder {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  border: 1px solid black;
  border-radius: 10px;

  p {
    border-top: 1px solid black;
    text-align: center;
    padding: rem(10px) 0;
  }
}

.playButtonWrap {
  display: flex;
}

.image {
  width: rem(320px);
  height: rem(320px);
  object-fit: cover;
  z-index: 2;
}

.videoWrap {
  object-fit: cover;
  z-index: 2;
  position: relative;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;

  p {
    border-top: 1px solid black;
    text-align: center;
    padding: rem(10px) 0;
  }
}

.imageLg {
  object-fit: cover;
  height: calc(100vh - 120px);
  border-radius: rem(15px);
  z-index: 2;
}

.playButton {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.galleryTitle {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-all;
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modalWrap {
  position: absolute;
  display: flex;
  top: 50%;
  padding: rem(10px);
  background-color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: rem(15px);
  flex: 1;
  align-items: center;
  justify-content: center;
}
