@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.switchTabsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: rem(10px) rem(30px);
  border-radius: rem(30px);
  box-shadow: $eycrowd-side-shadow;
}

.switchTabsText {
  padding: 0 rem(20px);
  font-size: $eycrowd-font-size-md;
  color: $eycrowd-color-light-gray;

  font-weight: 600;
  cursor: pointer;
}

.divider {
  width: rem(1px);
  height: rem(26px);
  background-color: $eycrowd-color-light-gray-01;
}

.switchTabsTextSelected {
  color: $eycrowd-color-black-02;
}

.content {
  display: flex;
  flex-direction: column;
}

.generatorContainer {
  padding-top: rem(50px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: rem(450px);
}

.actionButton {
  cursor: pointer;
  margin: rem(5px);
}

.actionsWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button {
  width: rem(450px);
}

.editIcon {
  width: rem(20px);
  height: rem(20px);
  cursor: pointer;
  margin: rem(5px);
  margin-bottom: rem(10px);
}

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.title {
  font-weight: normal;
  font-size: 18px;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}

.formWrap {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputWrapperSm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  width: 100%;
  height: rem(40px);
}

.submitButton {
  height: rem(50px);
  width: rem(200px);
}
