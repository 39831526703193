@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.label {
  font-size: rem(14px);
  line-height: 1.1;
  color: $eycrowd-color-light-gray-01;
  margin-bottom: rem(4px);
  font-weight: 500;
  display: flex;
}

.altField {
  justify-content: flex-start;

  .inputWrapper {
    margin-right: rem(20px);
  }
}

.inputWrapper {
  position: relative;
  padding-left: rem(25px);
  margin-top: rem(10px);
  margin-right: rem(30px);
}

.input {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: $eycrowd-z-index-input;
  cursor: pointer;
}

input:checked ~ .customCheckmark {
  background-color: $eycrowd-color-red;

  &:before {
    display: inline-block;
  }
}

.customCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: rem(20px);
  width: rem(20px);
  border: 2px solid $eycrowd-color-red;
  border-radius: $eycrowd-radius;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: none;
    transform: rotate(45deg);
    height: rem(10px);
    width: rem(4px);
    border-bottom: 2px solid $eycrowd-color-white;
    border-right: 2px solid $eycrowd-color-white;
    margin-bottom: 2px;
  }
}
