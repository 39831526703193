.tooltipOverride {
  background-color: white !important;
  border-color: black !important;
  opacity: 1 !important;
}

.label {
  font-size: 12px;
  font-weight: 500;
  max-width: 500px;
  white-space: normal;
}
