@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  padding: rem(60px) rem(80px) rem(50px);

  @include media-max($eycrowd-screen-mobile-landscape) {
    padding: rem(50px);
  }

  @include media-max($eycrowd-screen-mobile-portrait-lg) {
    padding: rem(16px);
  }
}

.link {
  display: block;
  opacity: 0.2;
  color: $eycrowd-color-black-02;
  font-size: rem(14px);
  font-weight: 500;
  text-align: center;
  line-height: 19px;
  margin-bottom: rem(61px);
}

.flexColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  max-width: rem(600px);
}

.inputWrapperSm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  max-width: rem(500px);
}

.inputField {
  display: flex;
  width: rem(500px);
  height: rem(40px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  overflow: hidden;
}

.input {
  width: 100%;
  border: none;
  padding: 0 rem(16px);
  font-size: $eycrowd-font-size-stack;
  color: $eycrowd-color-black-02;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
}

.submitButton {
  height: rem(50px);
  width: rem(200px);
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  width: 100%;
  max-width: rem(500px);
}
