@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.container {
  //background: black;
  //padding: rem(60px) rem(24px) rem(0px) rem(275px);
  padding: rem(80px) rem(24px) rem(28px) rem(275px);
}

.header {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  min-height: rem(72px);
  left: 0;
  right: 0;
  box-shadow: $eycrowd-shadow;
  padding: rem(26px) rem(56px);
  background: $eycrowd-color-white;
  z-index: $eycrowd-z-index-header;
}

.cancelButtonWrap {
  padding: rem(10px) rem(30px) rem(10px) rem(30px);
  cursor: pointer;
  border-radius: $eycrowd-radius-sm;
  border-width: rem(2px);
  border-style: solid;
  border-color: $eycrowd-color-light-gray-02;
  background-color: $eycrowd-color-white;
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-black-02;
  font-weight: 700;
}

.sideBar {
  background-color: $eycrowd-color-blue-02;
  height: 100%;
  width: rem(250px);
  padding: rem(10px);
  box-shadow: $eycrowd-side-shadow;
  z-index: $eycrowd-z-index-sidebar;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  padding: rem(60px) 0;

  img {
    width: rem(155px);
  }
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: rem(20px);
  color: $eycrowd-color-text;
  opacity: 0.9;
  transition: $eycrowd-transition;
  font-weight: 500;
}

.linkActive {
  background-color: $eycrowd-color-white;
  opacity: 1;
  box-shadow: $eycrowd-box-shadow;
  border-radius: $eycrowd-radius-md;
  font-weight: 600;
}

.mainHeadeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: rem(20px);
}

.mainHeadeRightWrapper {
  display: flex;
  align-items: center;
}

.titleMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-top: rem(5px);
  }
}

.title {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-all;
}

.titleMain {
  line-height: $eycrowd-font-line-height-stack;
  color: $eycrowd-color-blue-dark;
  word-break: break-all;
  padding-right: rem(5px);
}

.dashboardHeaderButton {
  padding: 0 rem(20px);
  width: rem(205px);
  height: rem(40px);
  margin-left: rem(34px);
  border-radius: $eycrowd-radius-sm !important;
}

.dashboardHeaderButtonLeft {
  justify-content: flex-start !important;
}

.createCampaignButtonIcon {
  margin-right: rem(18px);
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.status {
  margin-left: rem(16px);
}

.return {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.returnText {
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
  color: $eycrowd-color-blue-dark;
  margin-left: rem(6px);
}

.submitButton {
  padding: 0 rem(20px);
  width: rem(205px);
  height: rem(40px);
  border-radius: $eycrowd-radius-sm !important;
  margin-left: auto;
  margin-bottom: rem(10px);
}

.label {
  font-size: rem(14px);
  line-height: 1.1;
  color: $eycrowd-color-light-gray-01;
  margin-bottom: rem(4px);
  font-weight: 500;
  display: flex;
}

.arrowIcon {
  width: rem(10px);
  transform: rotate(90deg);
  margin-left: rem(15px);
}

.dropdownListItem {
  position: relative;
}

.dropdownWrapper {
  position: absolute;
  top: 10px;
  left: -75px;
  border-radius: $eycrowd-radius-sm;
  width: rem(155px);
  background: $eycrowd-color-white;
  box-shadow: $eycrowd-box-shadow;
  overflow: hidden;
}

.dropdownButton {
  background: transparent;
  width: 100%;
  padding: rem(10px) 0;
  font-size: rem(14px);
  border: none;
  transition: background-color 0.4s, color 0.4s;
  font-weight: 500;

  &:hover {
    background-color: $eycrowd-color-red;
    color: $eycrowd-color-white;
  }
}

.headerCompanyName {
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
}

.switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-right: rem(15px);
    margin-bottom: 0;
  }
}
