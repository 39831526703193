@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.approvalContentBox {
  position: sticky;
  top: 90px;
  padding: rem(16px);
  align-items: flex-start;
}

.approvalForm {
  width: 100%;
}

.approvalTextarea {
  min-height: rem(400px);
}

.button {
  width: 100%;
  padding: 7px;
  margin-bottom: 5px;
  font-weight: 600;
  border-radius: $eycrowd-radius-sm;
}

.successButton {
  background-color: $eycrowd-color-green;
}

.requestChangesButton {
  background-color: $eycrowd-color-blue;
}

.cancelButton {
  background-color: $eycrowd-color-red;
}
