@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.wrapper {
  border-bottom: $eycrowd-border-gray-01;
  margin-bottom: rem(16px);
}

.infoRow {
  display: flex;
  margin-bottom: rem(16px);
}

.label {
  width: 46%;
  opacity: 0.5;
  color: $eycrowd-color-black-03;
  font-size: $eycrowd-font-size-sm;
}

.infoText {
  width: 54%;
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
  font-size: $eycrowd-font-size-sm;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  color: #5fb467;
}

.verifiedText {
  font-size: rem(13px);
  font-weight: 500;
  margin-left: rem(8px);
}

.container {
  margin-top: rem(30px);
}

.title {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-word;
  font-weight: 600;
  margin-bottom: rem(22px);
}

.usersContainer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}

.usernameContainer {
  display: flex;
  justify-content: center;
  padding: rem(10px);
  font-size: $eycrowd-font-size-md;
  font-weight: 600;
  border-bottom: 1px solid rgb(247, 240, 240);
}

.users {
  display: flex;
  flex-wrap: wrap;

  p {
    width: 25%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    justify-content: center;
  }
}

.tasksContainer {
  padding-right: rem(133px);
  display: grid;
  grid-gap: rem(24px);

  @include media-max($eycrowd-screen-laptop) {
    padding-right: rem(50px);
  }
}

.table {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}
