@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.field {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: $eycrowd-font-size-stack;
}

.label {
  font-size: $eycrowd-font-size-sm;
  line-height: $eycrowd-font-line-height-xxs;
  color: $eycrowd-color-light-gray-01;
  margin-bottom: rem(4px);
  font-weight: 500;
  display: flex;
}

.input {
  height: rem(44px);
  width: 100%;
  padding: rem(10px) 0 rem(10px) rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  outline: none;
  font-family: $eycrowd-font-stack;
  color: $eycrowd-color-black-02;
  font-size: $eycrowd-font-size-stack;

  &::placeholder {
    color: $eycrowd-color-light-gray-01;
  }

  &:focus {
    border: 1px solid $eycrowd-color-red;
  }
}

.inputFieldLarge {
  font-size: $eycrowd-font-size-1-37x;
  border: none;
  padding: rem(10px) 0;
  margin-bottom: 0;
  font-weight: 600;

  &::placeholder {
    font-size: $eycrowd-font-size-1-37x;
  }

  &:focus {
    border: none;
  }
}

.required {
  border: $eycrowd-color-red;
}

.optional {
  position: absolute;
  bottom: rem(25px);
  right: rem(15px);
  font-size: $eycrowd-font-size-xs;
  font-weight: 600;
  opacity: 0.1;
  text-transform: uppercase;
  color: $eycrowd-color-blue-dark;
}

.eyeButton {
  border: none;
  background: transparent;
  opacity: 0.2;
  outline: transparent;
  position: absolute;
  top: rem(30px);
  right: rem(15px);
}

.multiSelectInput {
  & > div:first-of-type {
    font-size: $eycrowd-font-size-stack;
    min-height: rem(44px);
    border-radius: rem(10px);
    box-shadow: none;
    font-family: $eycrowd-font-stack;
  }
}

.selectInput {
  height: rem(44px);
  width: 100%;
  padding: rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  color: $eycrowd-color-light-gray-01;
  outline: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;

  @include media-max($eycrowd-screen-mobile-landscape) {
    width: 100%;
    min-width: 280px;
  }
}

.textarea {
  height: rem(60px);
}

.textareaLarge {
  height: rem(120px);
}

.textareaExtraLarge {
  height: rem(250px);
}

.checkboxButtonWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: rem(8px);

  @include media-max($eycrowd-screen-mobile-portrait-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(110px);
    width: 100%;
    font-size: $eycrowd-font-size-sm;
    padding: rem(15px) 0 rem(20px);
    position: relative;
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: $eycrowd-z-index-input;
    cursor: pointer;
  }

  .customCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 1px solid $eycrowd-color-light-gray-01;
    border-radius: $eycrowd-radius-md;

    &:hover {
      border: $eycrowd-border-active;
    }
  }

  input:checked ~ .customCheckmark {
    border: $eycrowd-border-active;
  }
}

.fileInput {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customFileInputField {
  background-color: $eycrowd-color-light-gray-02;
  border-radius: rem(10px);
  height: rem(150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.fileInputFieldIcon {
  margin-bottom: rem(10px);
}

.socialPlatformsItem {
  font-size: rem(14px);
  color: $eycrowd-color-blue-dark;
  margin-top: rem(5px);
}

.searchField {
  display: flex;
  width: rem(340px);
  height: rem(40px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  overflow: hidden;
}

.searchInput {
  width: 100%;
  border: none;
  padding: 0 rem(16px);
  font-size: $eycrowd-font-size-stack;
  color: $eycrowd-color-black-02;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
}

.searchIconWrapper {
  display: flex;
  align-items: center;
  padding: 0 rem(12px);
}
