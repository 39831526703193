@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  width: 100%;
}

.firstRow {
  display: flex;
  width: 100%;
}

.title {
  color: $eycrowd-color-black-02;
}

.label {
  min-width: rem(140px);
  opacity: 0.5;
  color: $eycrowd-color-black-03;
  margin-bottom: rem(6px);
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
}

.labelGap {
  margin-bottom: rem(12px);
}

.text {
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
  font-size: $eycrowd-font-size-sm;
  margin-bottom: rem(16px);
  word-break: break-all;
}

.map {
  height: rem(137px);
  width: 100%;
  overflow: hidden;
  border-radius: $eycrowd-radius-md;
  margin-bottom: rem(16px);
  // this is temporary, until dev adds a map insted of image
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.step {
  opacity: 0.5;
  color: $eycrowd-color-black-02;
  margin-bottom: rem(9px);
  font-size: $eycrowd-font-size-sm;
}

.supervisorTasksButton {
  height: rem(45px);
  margin-bottom: rem(16px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  background-color: $eycrowd-color-white;
  color: $eycrowd-color-light-gray-01;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: rem(20px);
    height: rem(20px);
    border: 2px solid $eycrowd-color-light-gray-01;
    border-radius: 50%;
    font-weight: 600;
  }
}

.disabled {
  background-color: rgba($eycrowd-color-light-gray, 0.1);
  opacity: 0.5;
  cursor: not-allowed;
}

.inputWrapper {
  display: grid;
  grid-template-columns: 84% 14%;
  align-items: end;
  grid-gap: 2%;
}

.saveButton {
  padding: 0 rem(20px);
  width: rem(180px);
  height: rem(40px);
  border-radius: $eycrowd-radius-sm !important;
  margin-left: auto;
}

.taskWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.textArea {
  height: rem(80px);
  padding: 0;
  margin-right: 10px;
}
