@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.maintenanceModeWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.maintenanceModeLabel {
  font-size: $eycrowd-font-size-sm;
  line-height: $eycrowd-font-line-height-xxs;
  color: $eycrowd-color-light-gray-01;
  font-weight: 500;
  display: flex;
}

.warningWrapper {
  position: absolute;
  top: rem(50px);
  border: 1px solid gray;
  border-radius: $eycrowd-radius-sm;
  padding: rem(7px);
}

.warningText {
  text-align: center;
  font-size: $eycrowd-font-size-sm;
  font-style: italic;
  color: $eycrowd-color-black-02;
}
