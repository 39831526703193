@import '../../styles/abstract/variables';
@import '../../styles/abstract/mixins';

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(1000px);
  width: rem(1000px);
  outline: none;
  border-radius: rem(15px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.title {
  font-weight: normal;
  font-size: 18px;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;
}

.formWrap {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputWrapperSm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  form {
    width: 100%;
  }
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  width: 100%;
  height: rem(40px);
}

.submitButton {
  height: rem(50px);
  width: rem(200px);
}

.textarea {
  height: rem(300px);
}

.spinner {
  width: 100%;
  height: rem(70px);
}

.card {
  border: 1px solid gray;
  margin-bottom: rem(10px);
  border-radius: 5px;
  padding: rem(4px);
  display: flex;
  flex-direction: column;
  gap: rem(10px);
}
