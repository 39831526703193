@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.paginationFooter {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: $eycrowd-shadow;
  padding-right: rem(5px);
  padding-left: rem(5px);
  border-radius: 0.25em;
  padding-top: rem(4px);
  padding-bottom: rem(4px);
  background-color: $eycrowd-color-white;
}

.paginationFooterBtn {
  padding-right: rem(15px);
  padding-left: rem(15px);
  padding-top: rem(7px);
  margin-right: rem(7px);
  margin-left: rem(7px);
  padding-bottom: rem(7px);
}

.paginationFooterBtnDisabled {
  cursor: default;
  pointer-events: none;
}

.paginationFooterBtnSelected {
  color: $eycrowd-color-blue-02;
  padding-right: rem(15px);
  padding-left: rem(15px);
  margin-right: rem(7px);
  margin-left: rem(7px);
  border-bottom: 1px solid $eycrowd-color-blue-02;
  padding-top: rem(7px);
  padding-bottom: rem(7px);
}

.buttonControl {
  padding-right: rem(10px);
  padding-left: rem(10px);
  padding-top: rem(7px);
  padding-bottom: rem(7px);
  border-right: 0.5px solid $eycrowd-color-light-gray-01;
}

.buttonControlRight {
  padding-right: rem(10px);
  padding-left: rem(10px);
  padding-top: rem(7px);
  padding-bottom: rem(7px);
  border-left: 0.5px solid $eycrowd-color-light-gray-01;
}

.numberWrap {
  width: rem(60px);
  max-width: rem(60px);
  :hover {
    background-color: $eycrowd-color-blue-02;
    color: $eycrowd-color-white;
  }
}

.controlBtnLeft {
  display: flex;
  flex: 1;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

  :hover {
    background-color: $eycrowd-color-blue-02;
    color: $eycrowd-color-white;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}

.controlBtnRight {
  display: flex;
  flex: 1;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  :hover {
    background-color: $eycrowd-color-blue-02;
    color: $eycrowd-color-white;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
