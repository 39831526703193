@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.spinner {
  height: unset;
}
.actionButton {
  background-color: transparent;
  border: none;
  height: rem(40px);
  padding: unset;

  margin-left: rem(10px);

  img {
    width: rem(24px);
    height: rem(24px);
    margin-bottom: rem(3px);
  }
  svg {
    width: rem(30px);
    height: rem(30px);
  }
}

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.title {
  font-weight: normal;
  font-size: 18px;
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}

.formWrap {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.inputWrapperSm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  width: 100%;
  height: rem(40px);
}

.submitButton {
  height: rem(50px);
  width: rem(200px);
}

.createByText {
  text-transform: none !important;
}
