@import '../../styles/abstract/variables';

.orderMediaContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem;
  justify-content: flex-start;
  align-items: center;
  row-gap: 2rem;
}
.item {
  border: 2px solid $eycrowd-color-blue-02;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  //width: calc(20vh + 2 * 10px + 2 * 1px);
  box-shadow: $eycrowd-box-shadow;
  display: flex;
  flex-direction: column;
}

.dragableItem {
  border: 2px solid $eycrowd-color-red-01;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  // width: calc(20vh + 2 * 10px + 2 * 1px);
  box-shadow: $eycrowd-box-shadow;
  display: flex;
  flex-direction: column;
}

.img {
  cursor: pointer;
  width: calc(20vh + 2 * 2rem + 2 * 1px);
  height: calc(20vh + 2 * 2rem + 2 * 1px);
  margin-bottom: 2rem;
}

.img img,
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.button {
  margin-left: auto;
}

.modalFeedWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: rem(15px);
}

.modalFeedInner {
  display: flex;
  flex-direction: column;
  max-width: calc(70vh + 5 * 10px + 2 * 1px);
  max-height: calc(70vh + 5 * 10px + 2 * 1px);
}

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.modalInner {
  display: flex;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.modalContent {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.inputSpan {
  display: flex;
  align-self: center;
}

.buttonSpan {
  margin-top: 1.5rem;
}

.spinner {
  height: unset;
}

.customFileInput {
  display: none;
}

.inputLabel {
  border: none;
  background: $eycrowd-color-blue-02;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.inputLabel:hover {
  background: $eycrowd-color-blue-01;
}
