@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.modalWrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: rem(700px);
  outline: none;
  border-radius: rem(15px);
  min-width: rem(700px);
  min-height: rem(500px);
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: rem(15px);
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
}

.modalTop {
  display: flex;
  width: 100%;
  padding: rem(20px);
  flex-direction: column;
  margin-top: rem(-5px);
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px);
  position: relative;
}

.actionButton {
  background-color: transparent;
  border: none;
}

.formWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}

.publishButton {
  padding: rem(16px) rem(50px);
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;
  position: absolute;
  bottom: rem(20px);
  right: rem(20px);
}

.uploadButton {
  background-color: #fd5f55;
  border-radius: rem(10px);
  cursor: pointer;
  width: rem(120px);
  height: rem(80px);
}

.publishButtonText {
  color: white;
}

.uploadButtonText {
  color: white;
}

.selectInput {
  height: rem(44px);
  width: 100%;
  padding: rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  color: $eycrowd-color-light-gray-01;
  outline: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
  font-size: 1rem;
  font-family: $eycrowd-font-stack;

  @include media-max($eycrowd-screen-mobile-landscape) {
    width: 100%;
    min-width: 280px;
  }
}

.formWrap {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.selectFieldWrap {
  margin-bottom: rem(10px);
}
