@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.gradeRangeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: rem(10px);
}

.label {
  opacity: 0.5;
  color: $eycrowd-color-black-03;
  margin-bottom: rem(6px);
  font-size: 16;
  font-weight: 500;
}

.text {
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
  font-size: $eycrowd-font-size-md;
  word-break: break-all;
}

.loadingSpinner {
  height: rem(40px);
  width: rem(40px);

  img {
    height: rem(40px);
    width: rem(40px);
  }
}

.switchContainer {
  height: rem(40px);
  display: flex;
  align-items: center;
}
