@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  height: rem(40px);
  width: rem(120px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  background-color: $eycrowd-color-white;
}

.requestChangesButton {
  background-color: $eycrowd-color-blue;
}

.userFullName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
}

.eyCrowdIcon {
  padding-left: 4px;
  height: 20px;
  width: 20px;
}
