@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

//style for date and time pickers
.react-datepicker {
  font-family: $eycrowd-font-stack !important;
  border: 1px solid $eycrowd-color-gray-01 !important;
  border-radius: $eycrowd-radius-md !important;
  overflow: hidden !important;
  box-shadow: $eycrowd-box-shadow;
}

.react-datepicker-popper {
  margin-top: rem(2px) !important;
  z-index: $eycrowd-z-index-date-time-pickers !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  background-color: $eycrowd-color-white !important;
  border-bottom: none !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 600 !important;
  color: $eycrowd-color-gray-01 !important;
}

.react-datepicker__day-names {
  opacity: 0.5 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $eycrowd-color-gray-01 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: $eycrowd-radius-round !important;
  background-color: $eycrowd-color-gray-01 !important;
  color: $eycrowd-color-white !important;
  font-weight: 600 !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &:hover {
    border-radius: $eycrowd-radius-round !important;
    background-color: $eycrowd-color-gray-01 !important;
    color: $eycrowd-color-white !important;
    opacity: 0.5;
  }
}

.react-datepicker__day--outside-month {
  opacity: 0.3 !important;
}

.react-datepicker__time-list-item {
  color: $eycrowd-color-gray-01 !important;

  &:hover {
    background-color: $eycrowd-color-gray-01 !important;
    color: $eycrowd-color-white !important;
    opacity: 0.5 !important;
  }
}

.react-datepicker__time-list-item--selected {
  background-color: $eycrowd-color-gray-01 !important;
  color: $eycrowd-color-white !important;
  font-weight: 600 !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  opacity: 0.5 !important;
  outline: none;

  &:hover {
    opacity: 1 !important;
  }
}

.react-datepicker__navigation--previous {
  left: rem(10px) !important;
  border-right-color: $eycrowd-color-gray-01 !important;
}

.react-datepicker__navigation--next {
  right: em(10px) !important;
  border-left-color: $eycrowd-color-gray-01 !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  opacity: 0.3;
}
