@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.titleMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    margin-top: rem(5px);
  }
}

.titleMain {
  line-height: $eycrowd-font-line-height-stack;
  color: $eycrowd-color-blue-dark;
  word-break: break-all;
  padding-right: rem(5px);
}

.cancelButtonWrap {
  padding: rem(10px) rem(30px) rem(10px) rem(30px);
  cursor: pointer;
  border-radius: $eycrowd-radius-sm;
  border-width: rem(2px);
  border-style: solid;
  border-color: $eycrowd-color-light-gray-02;
  background-color: $eycrowd-color-white;
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-black-02;
  font-weight: 700;
}

.form {
  width: rem(450px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  display: flex;
  width: rem(50px);
  justify-content: center;
  align-items: center;
  height: rem(50px);
}

.loadingSpinnerImg {
  height: rem(50px);
  width: rem(50px);
}
.formExplanation {
  padding-top: rem(20px);
  padding-bottom: rem(40px);
}

.galleryMediaContainer {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: calc(20vh + 2 * 10px + 2 * 1px);
  box-shadow: $eycrowd-box-shadow;
}

.selectedGalleryMediaContainer {
  cursor: pointer;
  position: relative;
  border: 2px solid $eycrowd-color-blue;
  border-radius: 10px;
  padding: 9px;
  margin: 10px;
  width: calc(20vh + 2 * 10px + 2 * 1px);
  box-shadow: $eycrowd-box-shadow;
}

.imageLg {
  object-fit: cover;
  height: 20vh;
  width: 20vh;
  border-radius: rem(15px);
}

.staffGalleryActivityWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selectedIconAbsoluteWrapper {
  position: absolute;
  top: 7px;
  right: 5px;
}

.downloadButton {
  padding: rem(10px) rem(30px) rem(10px) rem(30px);
  cursor: pointer;
  border-radius: $eycrowd-radius-sm;
  border: 1px solid $eycrowd-color-light-gray-01;
  background-color: $eycrowd-color-white;
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-black-02;
  font-weight: 700;
  min-width: rem(135px);
}

.emptyDiv {
  padding: rem(10px) rem(30px) rem(10px) rem(30px);
  min-width: rem(135px);
}

.dateLabel {
  padding-bottom: rem(5px);
}

.byDayWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: rem(2px);
}

.perDayNumberText {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
}
.staffActivityByDaysWrapper {
  padding-top: rem(10px);
  padding-bottom: rem(10px);
}
.noContentFoundForThisDateIntervalText {
  padding-top: rem(10px);
  padding-bottom: rem(10px);
}

.downloadWrapper {
  position: relative;
}

.downloadTextWrapper {
  position: absolute;
  top: rem(50px);
  border: 1px solid gray;
  border-radius: $eycrowd-radius-sm;
  padding: rem(5px);
}

.downloadTextStyling {
  text-align: center;
  font-size: $eycrowd-font-size-sm;
  font-style: italic;
  color: $eycrowd-color-black-02;
}
